import React, { useContext, useEffect } from "react"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import {
  GlobalStateContext,
  GlobalDispatchContext
} from "../context/GlobalContextProvider"

const SignedInPage = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const routeUser = () => {
    if (state.auth_state) {
      if (state.role === "operator") {
        navigate('/bookings')
        dispatch({ type: 'ROUTE', route: "/bookings" });
      }
      else {
        navigate('/users')
        dispatch({ type: 'ROUTE', route: "/users" });
      }
    }
  }
  useEffect(routeUser, [state.auth_state, state.role]);

  return (
    <SEO title="Signing In..." />
  )
}

export default SignedInPage
